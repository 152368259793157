@import "~@ctrl/ngx-emoji-mart/picker";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html * {
        padding: 0;
        margin: 0;
    }
    @font-face {
        font-family: "Quicksand";
        src: url("assets/fonts/Quicksand-VariableFont_wght.woff2")
                format("woff2-variations"),
            url("assets/fonts/Quicksand-VariableFont_wght.woff")
                format("woff-variations");
        font-weight: 300 700;
    }
    html {
        height: 100%;
    }
    body {
        font-family: Quicksand;
        height: 100dvh;
        @apply text-brand-body;
    }
    p {
        @apply leading-6;
    }
    .fade-out-left-animation {
        animation: fadeOutLeft 500ms ease-in-out;
    }
    .fade-out-right-animation {
        animation: fadeOutRight 500ms ease-in-out;
    }

    @keyframes fadeOutLeft {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateX(-100%);
        }
    }
    @keyframes fadeOutRight {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .image-cropper {
        height: 60vh;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-brand-title;
    }
    /* placeholder code */
    [contentEditable]:empty:before {
        content: attr(placeholder);
        opacity: 0.6;
    }

    [contentEditable]:empty:focus::before {
        content: "";
    }
    #border-dash {
        border: 1.75px dashed rgba(112, 112, 112, 0.3);
    }
    #match {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center top;
        background-attachment: fixed;
        height: 100%;
        width: 100%;
    }
    @media (min-width: 768px) {
        #match {
            background-size: calc(80vh * 9 / 16);
        }
    }

    #notification {
        transition: transform 1s;
        transition-delay: 2s;
        transform: translate(20px, 20px);
    }
    #modal {
        margin-top: -4rem;
        width: 100%;

        transition: transform 1s;
        transform: translate(20px, 20px);
    }
    @media (min-width: 768px) {
        #modal {
            height: 400px;
        }
    }
    #bounce {
        transition: transform 2s, transform 2s;
    }
    input[type="password"]::-ms-reveal,
    input[type="password"]::-ms-clear {
        display: none;
    }

    #bounce:hover {
        transform: translateY(240px);
        transform: translateY(120px);
    }

    #shop-item:target {
        transform: translateX(-50%);
    }

    @keyframes slidein {
        0% {
            transform: translateY(250px);
        }
        100% {
            transform: translateY(0);
        }
    }
    .main-navbar {
        background-position: center center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: white;
        height: 100%;
    }
    @media (min-width: 769px) {
        .main-navbar {
            background-image: url(assets/images/dashboard_background.jpg);
        }
    }
    .main-navbar2 {
        background-position: center center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: white;
        height: 100%;
    }
    @media (min-width: 769px) {
        .main-navbar2 {
            background-image: url(assets/images/dashboard_background.jpg);
        }
    }
    @media (min-width: 769px) {
        #start-page-section {
            background-image: url(assets/images/dekstop_hearts_background.png);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center top;
        }
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@layer utilities {
    .link {
        @apply underline text-brand-cyan;
    }
    .modal-link {
        @apply text-base font-semibold underline text-brand-cyan;
    }
    .profile-subtitle {
        @apply text-brand-body text-sm;
    }
    .content {
        @apply text-brand-body text-xs font-semibold;
    }
    .content-brand-title {
        @apply text-brand-title text-center font-semibold;
    }
    .heading-magenta {
        @apply text-lg text-brand-magenta font-semibold;
    }
    .subheading-brand-title {
        @apply text-black font-semibold text-[18px];
    }
    .modal-brand-title {
        @apply text-brand-title font-bold text-3xl;
    }
    .header {
        @apply font-bold text-3xl;
    }
    .flow-text {
        @apply text-brand-body;
        font-size: 1rem;
        line-height: 2;
    }

    /** Home Page (not loggedIn) */
    /** Flow Text in Home Page */
    .flow-text-home {
        @apply text-brand-body;
        font-size: 1rem;
        line-height: 2;
    }

    /** Button Labels */
    .button-labels {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 700;
    }

    /** Headers in Homepage */
    .header-home {
        font-size: 2rem;
        font-size: 700;
    }

    /** Global(loggedIn)*/

    .flow-text-logged-in {
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: 400;
    }

    /** Header h1 */
    .heading-1 {
        font-size: 1.7rem;
        font-weight: 700;
    }

    /** Header h2 */
    .heading-2 {
        font-size: 1.25rem;
        line-height: 2.25rem;
        font-weight: 700;
    }
    .heading-3 {
        font-size: 1rem;
        line-height: 2.25rem;
        font-weight: 700;
    }

    /** Inputs-field */
    /** Inputs */
    .input {
        font-family: inherit;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: inherit;
    }
    /** Placeholder*/
    .input-placeholder {
        font-family: inherit;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: inherit;
        color: inherit;
    }
    /** Properties like input Tag */
    .input-inherit {
        font-family: inherit;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: inherit;
    }

    /** Placeholder */
    .placeholder {
        font-family: inherit;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: inherit;
    }

    /** Properties like input placeholder */
    .placeholder-inherit {
        font-family: inherit;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: inherit;
    }

    /** Input Labels */
    .label {
        font-size: 0.875rem;
        font-weight: 700;
    }

    .landing-section-heading {
        font-weight: 700;
        font-size: 2rem;
    }
    .md-height {
        height: 80vh;
    }
    .md-width {
        width: calc(80vh * 9 / 16);
    }
}
:host {
    display: block;
    perspective: 600px;
}

.card {
    box-shadow: 10px 10px 43px -18px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    transform-style: preserve-3d;
}

.face {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    backface-visibility: hidden;
    background-size: cover;
}

.front {
    transform: rotateY(180deg);
}

* {
    -webkit-tap-highlight-color: transparent;
}
/**
 * make contenteditable also works for safari
 */
[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}
